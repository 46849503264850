import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/statistics/user/regional_distribution',
  component: Layout,
  name: 'StatisticsUser',
  meta: {
    title: '用户总览',
    icon: 'mdi:user',
  },
  children: [
    {
      path: '',
      name: 'RegionalDistribution',
      component: () => import('@/views/statistics/user/regional.distribution.vue'),
      meta: {
        title: '总览',
        icon: 'bx:world',
        sidebar: false,
        breadcrumb: false,
      },
    },
  ],
}

export default routes
