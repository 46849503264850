import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/statistics/iot/overview',
  component: Layout,
  name: 'StatisticsIot',
  meta: {
    title: '设备总览',
    icon: 'ion:hardware-chip',
  },
  children: [
    {
      path: '',
      name: 'IotOverview',
      component: () => import('@/views/statistics/iot/iot.overview.vue'),
      meta: {
        title: '设备总览',
        sidebar: false,
        breadcrumb: false,
      },
    },
  ],
}

export default routes
