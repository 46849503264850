import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/customer',
  component: Layout,
  redirect: '/customer/list',
  name: 'customer',
  meta: {
    title: '客户',
    icon: 'la:user-tie',
  },
  children: [
    {
      path: 'list',
      name: 'customerList',
      component: () => import('@/views/customer/list.vue'),
      meta: {
        title: '客户管理',
        icon: 'la:user-tie',
      },
    },
    {
      path: 'create',
      name: 'customerCreate',
      component: () => import('@/views/customer/detail.vue'),
      meta: {
        title: '新增',
        sidebar: false,
        activeMenu: '/customer/list',
      },
    },
    {
      path: 'edit/:id',
      name: 'customerEdit',
      component: () => import('@/views/customer/detail.vue'),
      meta: {
        title: '编辑',
        sidebar: false,
        activeMenu: '/customer/list',
      },
    },
    {
      path: 'detail/:id',
      name: 'customerDetail',
      component: () => import('@/views/customer/detail.vue'),
      meta: {
        title: '详情',
        sidebar: false,
        activeMenu: '/customer/list',
      },
    },
  ],
}

export default routes
