import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/manage/system',
  component: Layout,
  redirect: '/manage/system/dictionary',
  name: 'SystemManage',
  meta: {
    title: '系统',
    icon: 'icon-park-outline:system',
  },
  children: [
    {
      path: 'dictionary',
      name: 'SystemManageDictionary',
      meta: {
        title: '字典管理',
        icon: 'fluent-mdl2:dictionary',
      },
      children: [
        {
          path: '',
          name: 'SystemManageDictionaryList',
          component: () => import('@/views/system/dictionary/index.vue'),
          meta: {
            title: '字典列表',
            sidebar: false,
          },
        },
      ],
    },

    {
      path: 'appConfig',
      name: 'SystemManageAppConfig',
      meta: {
        title: 'app配置',
        icon: 'ep:document',
      },
      children: [
        {
          path: '',
          name: 'SystemManageAppConfigList',
          component: () => import('@/views/system/app_config/list.vue'),
          meta: {
            title: '配置列表',
            sidebar: false,
            breadcrumb: false,
          },
        },
        {
          path: 'create',
          name: 'SystemManageAppConfigCreate',
          component: () => import('@/views/system/app_config/detail.vue'),
          meta: {
            title: '新增配置',
            // i18n: 'route.general.department.create',
            sidebar: false,
            activeMenu: '/manage/system/app_config',
          },
        },
        {
          path: 'edit/:id',
          name: 'SystemManageAppConfigEdit',
          component: () => import('@/views/system/app_config/detail.vue'),
          meta: {
            title: '修改配置',
            sidebar: false,
            activeMenu: '/manage/system/app_config',
          },
        },
        {
          path: 'detail/:id',
          name: 'SystemManageAppConfigDetail',
          component: () => import('@/views/system/app_config/detail.vue'),
          meta: {
            title: '历史配置',
            sidebar: false,
            activeMenu: '/manage/system/app_config',
          },
        },
        {
          path: 'hisConfig/:id',
          name: 'SystemManageAppConfigHisDetail',
          component: () => import('@/views/system/app_config/detail.vue'),
          meta: {
            title: '配置信息',
            sidebar: false,
            activeMenu: '/manage/system/app_config',
          },
        },
      ],
    },

  ],
}

export default routes
