import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/manage/sdk',
  component: Layout,
  redirect: '/manage/sdk/wechat',
  name: 'SdkManage',
  meta: {
    title: 'SDK',
    icon: 'i-ep:coin',
  },
  children: [
    {
      path: 'wechat',
      name: 'WeChatMiniProgram',
      meta: {
        title: '微信 SDK',
        icon: 'i-ep:link',
      },
      children: [
        {
          path: '',
          name: 'SdkWechat',
          component: () => import('@/views/sdk/wechat/sdk.vue'),
          meta: {
            title: '小程序 Sdk',
            sidebar: false,
          },
        },
      ],
    },
    {
      path: 'ios',
      name: 'IosProgram',
      meta: {
        title: 'IOS SDK',
        icon: 'i-ep:iphone',
      },
      children: [
        {
          path: '',
          name: 'SdkIos',
          component: () => import('@/views/sdk/ios/sdk.vue'),
          meta: {
            title: 'ios Sdk',
            sidebar: false,
          },
        },
      ],
    },
    {
      path: 'android',
      name: 'AndroidProgram',
      meta: {
        title: 'Android SDK',
        icon: 'i-ep:cellphone',
      },
      children: [
        {
          path: '',
          name: 'SdkAndroid',
          component: () => import('@/views/sdk/android/sdk.vue'),
          meta: {
            title: 'android Sdk',
            sidebar: false,
          },
        },
      ],
    },
    {
      path: 'flutter',
      name: 'FlutterProgram',
      meta: {
        title: 'Flutter SDK',
        icon: 'i-iconamoon:shield-light',
      },
      children: [
        {
          path: '',
          name: 'SdkFlutter',
          component: () => import('@/views/sdk/flutter/sdk.vue'),
          meta: {
            title: 'flutter Sdk',
            sidebar: false,
          },
        },
      ],
    },
    {
      path: 'uniapp',
      name: 'UniappProgram',
      meta: {
        title: 'Uniapp SDK',
        icon: 'i-ep:help',
      },
      children: [
        {
          path: '',
          name: 'SdkUniapp',
          component: () => import('@/views/sdk/uniapp/sdk.vue'),
          meta: {
            title: 'uniapp Sdk',
            sidebar: false,
          },
        },
      ],
    },
  ],
}

export default routes
