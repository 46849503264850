import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/manage/authority',
  component: Layout,
  redirect: '/manage/authority/permission',
  name: 'AuthorityManage',
  meta: {
    title: '权限',
    icon: 'material-symbols:shield-lock-outline-rounded',
  },
  children: [
    {
      path: 'permission',
      name: 'AuthorityManagePermission',
      meta: {
        title: '权限管理',
        icon: 'mdi:shield-key-outline',
      },
      children: [
        {
          path: '',
          name: 'AuthorityManagePermissionList',
          component: () => import('@/views/authority/permission/list.vue'),
          meta: {
            title: '权限列表',
            sidebar: false,
            cache: ['AuthorityManagePermissionCreate', 'AuthorityManagePermissionEdit'],
          },
        },
        {
          path: 'create',
          name: 'AuthorityManagePermissionCreate',
          component: () => import('@/views/authority/permission/detail.vue'),
          meta: {
            title: '新增权限',
            sidebar: false,
            activeMenu: '/manage/authority/permission',
          },
        },
        {
          path: 'edit/:id',
          name: 'AuthorityManagePermissionEdit',
          component: () => import('@/views/authority/permission/detail.vue'),
          meta: {
            title: '编辑权限',
            sidebar: false,
            activeMenu: '/manage/authority/permission',
          },
        },
      ],
    },

    {
      path: 'role',
      name: 'AuthorityManageRole',
      meta: {
        title: '角色管理',
        icon: 'ph:users',
      },
      children: [
        {
          path: '',
          name: 'AuthorityManageRoleList',
          component: () => import('@/views/authority/role/list.vue'),
          meta: {
            title: '角色列表',
            sidebar: false,
            breadcrumb: false,
            cache: ['AuthorityManageRoleCreate', 'AuthorityManageRoleEdit'],
          },
        },
        {
          path: 'create',
          name: 'AuthorityManageRoleCreate',
          component: () => import('@/views/authority/role/detail.vue'),
          meta: {
            title: '新增角色',
            // i18n: 'route.general.department.create',
            sidebar: false,
            activeMenu: '/manage/authority/role',
          },
        },
        {
          path: 'edit/:id',
          name: 'AuthorityManageRoleEdit',
          component: () => import('@/views/authority/role/detail.vue'),
          meta: {
            title: '编辑角色',
            // i18n: 'route.general.department.edit',
            sidebar: false,
            activeMenu: '/manage/authority/role',
          },
        },
      ],
    },

    {
      path: 'menu',
      name: 'pagesExampleGeneralMenu2',
      component: () => import('@/views/authority/menu/index.vue'),
      meta: {
        title: '导航管理',
        icon: 'bx:bx-menu',
      },
    },
  ],
}

export default routes
