import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/manage/watch_faces',
  component: Layout,
  redirect: '/manage/watch_faces',
  name: 'WatchFaces',
  meta: {
    title: '表盘',
    icon: 'ph:watch',
  },
  children: [
    {
      path: '',
      name: 'WatchFacesManage',
      meta: {
        title: '表盘管理',
        icon: 'ph:watch',
      },
      children: [
        {
          path: '',
          name: 'WatchFacesManageList',
          component: () => import('@/views/watch_faces/index/list.vue'),
          meta: {
            title: '表盘列表',
            sidebar: false,
            cache: ['WatchFacesManageCreate', 'WatchFacesManageEdit'],
          },
        },
        {
          path: 'create',
          name: 'WatchFacesManageCreate',
          component: () => import('@/views/watch_faces/index/detail.vue'),
          meta: {
            title: '新增表盘',
            sidebar: false,
            activeMenu: '/manage/watchfaces',
          },
        },
        {
          path: 'edit/:id',
          name: 'WatchFacesManageEdit',
          component: () => import('@/views/watch_faces/index/detail.vue'),
          meta: {
            title: '编辑表盘',
            sidebar: false,
            activeMenu: '/manage/watchfaces',
          },
        },
      ],
    },
    {
      path: 'type',
      name: 'WatchFacesTypeManage',
      meta: {
        title: '分类管理',
        icon: 'bi:tags',
      },
      children: [
        {
          path: '',
          name: 'WatchFacesTypeManageList',
          component: () => import('@/views/watch_faces/type/list.vue'),
          meta: {
            title: '分类列表',
            sidebar: false,
            cache: ['WatchFacesTypeManageCreate', 'WatchFacesTypeManageEdit'],
          },
        },
        {
          path: 'create',
          name: 'WatchFacesTypeManageCreate',
          component: () => import('@/views/watch_faces/type/detail.vue'),
          meta: {
            title: '新增分类',
            // i18n: 'route.general.department.create',
            sidebar: false,
            activeMenu: '/manage/watch_faces/type',
          },
        },
        {
          path: 'edit/:id',
          name: 'WatchFacesTypeManageEdit',
          component: () => import('@/views/watch_faces/type/detail.vue'),
          meta: {
            title: '编辑分类',
            // i18n: 'route.general.department.edit',
            sidebar: false,
            activeMenu: '/manage/watch_faces/type',
          },
        },
      ],
    },
  ],
}

export default routes
