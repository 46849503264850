import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/manage/iot',
  component: Layout,
  redirect: '/manage/iot/device',
  name: 'IotManage',
  meta: {
    title: '硬件',
    icon: 'ion:hardware-chip-outline',
  },
  children: [
    {
      path: 'device',
      name: 'IotManageDevice',
      meta: {
        title: '设备管理',
        icon: 'ion:watch-outline',
      },
      children: [
        {
          path: '',
          name: 'IotManageDeviceList',
          component: () => import('@/views/iot/device/list.vue'),
          meta: {
            title: '设备列表',
            sidebar: false,
            cache: ['IotManageDeviceCreate', 'IotManageDeviceEdit'],
          },
        },
        {
          path: 'create',
          name: 'IotManageDeviceCreate',
          component: () => import('@/views/iot/device/detail.vue'),
          meta: {
            title: '新增设备',
            sidebar: false,
            activeMenu: '/manage/iot/device',
          },
        },
        {
          path: 'edit/:id',
          name: 'IotManageDeviceEdit',
          component: () => import('@/views/iot/device/detail.vue'),
          meta: {
            title: '编辑设备',
            sidebar: false,
            activeMenu: '/manage/iot/device',
          },
        },
      ],
    },
    {
      path: 'device/models',
      name: 'IotManageDeviceModels',
      meta: {
        title: '设备型号管理',
        icon: 'material-symbols:devices-wearables-outline',
      },
      children: [
        {
          path: '',
          name: 'IotManageDeviceModelsList',
          component: () => import('@/views/iot/models/list.vue'),
          meta: {
            title: '设备型号列表',
            sidebar: false,
            cache: ['IotManageDeviceModelsCreate', 'IotManageDeviceModelsEdit'],
          },
        },
        {
          path: 'create',
          name: 'IotManageDeviceModelsCreate',
          component: () => import('@/views/iot/models/detail.vue'),
          meta: {
            title: '新增设备型号',
            // i18n: 'route.general.department.create',
            sidebar: false,
            activeMenu: '/manage/iot/device/models',
          },
        },
        {
          path: 'edit/:id',
          name: 'IotManageDeviceModelsEdit',
          component: () => import('@/views/iot/models/detail.vue'),
          meta: {
            title: '编辑设备型号',
            // i18n: 'route.general.department.edit',
            sidebar: false,
            activeMenu: '/manage/iot/device/models',
          },
        },
      ],
    },
  ],
}

export default routes
