import Mock from 'mockjs'

const AllList: any[] = []
for (let i = 0; i < 50; i++) {
  AllList.push(Mock.mock({
    id: '@id',
    defaultName: '@ctitle(2, 10)',
    languages: [{
      id: 1,
      name: 'cn',
      isDefault: true,
    }, {
      id: 2,
      name: 'en',
      isDefault: false,
    }],
    state: '@boolean',
    defaultThumbnailPath: 'https://th.bing.com/th?id=ODLS.2e71af61-6a5a-4385-b77e-f1eab9a9f6d8&w=32&h=32&qlt=94&pcl=fffffa&o=6&pid=',
    defaultBackgroundImagePath: 'https://th.bing.com/th?id=ODLS.2e71af61-6a5a-4385-b77e-f1eab9a9f6d8&w=32&h=32&qlt=94&pcl=fffffa&o=6&pid=',
    defaultFilePath: 'https://th.bing.com/th?id=ODLS.2e71af61-6a5a-4385-b77e-f1eab9a9f6d8&w=32&h=32&qlt=94&pcl=fffffa&o=6&pid=',
    supportedModels: [
      { id: 1, name: 'F100' },
      { id: 2, name: 'E80' },
      { id: 3, name: 'E86' },
      { id: 4, name: 'P8' },
      { id: 5, name: 'W10' },
    ],
    wfType: '@integer(0,2)',
    types: [
      { id: 1, name: '这是分类', language: 'cn' },
    ],
  }))
}

export default [
  {
    url: '/mock/watch_faces/list',
    method: 'get',
    response: (option: any) => {
      const { name, from, limit } = option.query
      const list = AllList.filter((item) => {
        return name ? item.name.includes(name) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: 'ok',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/watch_faces/detail',
    method: 'get',
    response: (option: any) => {
      const info = AllList.filter(item => item.id === option.query.id)
      return {
        message: 'ok',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/watch_faces/create',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/watch_faces/edit',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/watch_faces/delete',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]
